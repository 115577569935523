<template >
  <v-container >
    <v-row no-gutters class="d-flex justify-center">
      <v-col
      xs="2.4"
      cols="2.4"
      sm="2.4"
      xl=""
      md="4"
      >
      <v-card
    class="mx-auto item-card ma-2 pa-2"
    max-width="344"
  >
    <v-img 
      class="promo-image"
      src="../assets/card-1.png"
    
    ></v-img>

    <v-card-text>
      Start fresh every month without any negative carryover
    </v-card-text>
  </v-card>

  
      </v-col>

      <v-col
       
      xs="2.4"
      cols="2.4"
      sm="2.4"
      xl=""
      md="4"

     >
     <v-card
   class="mx-auto item-card ma-2 pa-2"
   max-width="344"
   
 >
   <v-img
   class="promo-image"
     src="../assets/card-2.png"
   
   ></v-img>

   <v-card-text>
     Start fresh every month without any negative carryover
   </v-card-text>
 </v-card>

 
     </v-col>

     <v-col
     xs="2.4"
      cols="2.4"
      sm="2.4"
      xl=""
      md="4"
     >
     <v-card
   class="mx-auto item-card ma-2 pa-2"
   max-width="344"
   ma-2 pa-2
 >
   <v-img
   class="promo-image"
     src="../assets/card-3.png"
   
   ></v-img>

   <v-card-text>
     Start fresh every month without any negative carryover
   </v-card-text>
 </v-card>
     </v-col>

     <v-col
     xs="12"
      cols="2.4"
      sm="2.4"
      xl=""
      md="4"
     >
     <v-card
   class="mx-auto item-card ma-2 pa-2"
   max-width="344"
   ma-2 pa-2
 >
   <v-img
   class="promo-image"
     src="../assets/card-4.png"
   
   ></v-img>

   <v-card-text>
     Start fresh every month without any negative carryover
   </v-card-text>
 </v-card>

 
     </v-col>

     <v-col
     xs="12"
      cols="2.4"
      sm="2.4"
      xl=""
      md="4"
     >
     <v-card
   class="mx-auto item-card ma-2 pa-2"
   max-width="344"
   ma-2 pa-2
 >
   <v-img
   class="promo-image"
     src="../assets/card-5.png"
   
   ></v-img>

   <v-card-text>
     Start fresh every month without any negative carryover
   </v-card-text>
 </v-card>
     </v-col>

    </v-row>
  </v-container>
</template>



<style>

.promo-image img {
  height: 85% !important;
}

.v-card-text {
  color: #f0d8a7;
    font-size: large;
    text-align: center;
}

.item-card:hover {
  transform: translateY(-10px) scale(1.02) !important;
    box-shadow: 0 1vw 28px rgb(0 0 0 / 25%), 0 1vw 8px rgb(0 0 0 / 10%);
    -webkit-transition: .45s ease;
    transition: .45s ease;
}

</style>

<script>
  export default {
    data: () => ({
      show: false,
    }),
  }
</script>