<template>
    
    <h2 class="text-center b-title">{{ title }}</h2>
    <v-spacer v-if="!isMobile" class="text-center" style="flex-grow: 0.4 !important;"></v-spacer>
    <v-spacer v-if="isMobile" class="text-center" style="flex-grow: 0.2 !important;"></v-spacer>
</template>



<script>

export default {
    data() {
      return {
        isMobile: false,
      };
    },
    name: 'HeadingBlock',
    props: {
        title: String
    },
    mounted() {
    // Check if the screen width is less than 600px (for example)
    this.isMobile = window.innerWidth < 600;
    
    // Listen for window resize events to update isMobile
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 600;
      
    });
  },
}


</script>


<style>
.b-title {
  
    color: #f0d8a7;
  
}

</style>