<template>
    <v-app :theme="theme">
      
    <MainNav></MainNav>
  
      <v-main>
        
          <HeroSection v-if="isMobile" style="height: 100vh; max-height: 100%;"/>
          <HeroSection v-if="!isMobile"/>
         <v-spacer class="pa-md-4 mx-lg-auto" ></v-spacer>
          <HeadingBlock title="Why CASINERX"></HeadingBlock>
          <MainContent/>
      </v-main>
    </v-app>
  </template>
  
  
  <style>
  .v-toolbar__content {
    display: flex;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&display=swap');
  
  
  body {
    font-family: 'Cinzel', serif;
  }
  
  .main-logo  {
    text-decoration: none;
    font-size: xx-large;
      font-weight: 600;
    background: -webkit-linear-gradient(#f0d8a7, white);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  
  </style>
  
  <script setup>
    import { ref } from 'vue'
  
    const theme = ref('dark')
  
  
  
    
  </script>
  
  <script>
  import HeroSection from './HeroSection.vue';
  import MainContent from './MainContent.vue';
  import MainNav from './elements/MainNav.vue';
import HeadingBlock from './elements/HeadingBlock.vue';

  
  export default {
   
  
    components: {
    HeroSection,
    MainContent,
    MainNav,
    HeadingBlock,
},
    data() {
      return {
        isMobile: false,
        desktopImageUrl: 'hero-desktop.png',
        mobileImageUrl: 'hero-mobile.png'
      };
    },
  
    mounted() {
      // Check if the screen width is less than 600px (for example)
      this.isMobile = window.innerWidth < 600;
      
      // Listen for window resize events to update isMobile
      window.addEventListener('resize', () => {
        this.isMobile = window.innerWidth < 600;
      });
    },
  }
  </script>
  
  
  