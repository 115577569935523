<template >
    <v-img
    
    class="hero-image"
    :src="desktopImageUrl"
    :lazy-src="desktopImageUrl"
    >
    <v-lazy
    v-if="!isMobile"
  
  :options="{'threshold':0.5}"
  transition="fade-transition"
>


</v-lazy>
    <v-img v-if="isMobile" class="mobile-hero">

    </v-img>
    <div v-if="isMobile" class="promo-box-mobile">
      <h1 class="promo-1-mobile">6000 +</h1>
<h2 class="promo-2-mobile">Games with the highest RTP on the market, trusted providers who are constantly updating their games</h2>
<v-spacer class="pa-md-4 mx-lg-auto" ></v-spacer>
<router-link to="/contact">
  <v-btn class="hero-button-mobile">Join Us Today</v-btn>
</router-link>

</div>
   
    <div v-if="!isMobile" class="promo-text">
    <h1 class="promo-1">6000 +</h1>
<h2 class="promo-2 w-25">Games with the highest RTP on the market, trusted providers who are constantly updating their games.</h2>
<v-spacer class="pa-md-4 mx-lg-auto" ></v-spacer>
<router-link to="/contact">
<v-btn class="hero-button">Join Us Today</v-btn>
</router-link>
</div>

</v-img>
     


</template>


<style>

.promo-box-mobile {
  width: 84vw;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 52vh;
    left: 0;
    right: 0;
}

.hero-main {
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: relative;
}

.promo-text {
    position:absolute;
    top:15vh;
    left:10vw;

}

.promo-1-mobile {
  color: #f0d8a7;
}

.promo-1 {
    font-size:85px;
    color: #f0d8a7;
}



.hero-button {
    background: linear-gradient(
    to right,    
    #f0d8a7, white);
    color: black !important;
    font-weight: 600;
}

.hero-button-mobile {
  margin-top:30px;
  background: linear-gradient(
    to right,    
    #f0d8a7, white);
    color: black !important;
    font-weight: 600;
}

.mobile-hero {
    background: linear-gradient(180deg,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 40%),url(../assets/hero-mobile.png) center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    height: 100%;
}

</style>


<script>
export default {
  data() {
    return {
      isMobile: false,
      desktopImageUrl: 'hero-desktop.png',
      mobileImageUrl: 'hero-mobile.png'
    };
  },
  mounted() {
    // Check if the screen width is less than 600px (for example)
    this.isMobile = window.innerWidth < 600;
    
    // Listen for window resize events to update isMobile
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 600;
      
    });
  },
};
</script>





