<template>
  

<v-app-bar app >
  <v-app-bar-nav-icon v-if="drawer == false" @click="drawer = true" 
                      class="d-flex d-sm-none"
                      >
                    </v-app-bar-nav-icon>
      <v-btn v-if="drawer == true"
      icon
      class="mr-2"
      @click="drawer = false"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  
   <v-toolbar-title v-if="!isMobile" class="main-logo"><router-link to="/">CSX PARTNERS</router-link> </v-toolbar-title>
   <v-toolbar-title v-if="isMobile" class="main-logo-mob"><router-link to="/">CSX PARTNERS</router-link></v-toolbar-title>
   <v-spacer></v-spacer>
 
   <v-tabs
      v-model="tab"
      align-with-title
      class="d-none d-sm-flex"
    >
    <router-link
        v-for="(item, index) in items"
        :key="index"
        :to="item.routerLink"
        exact
        class="v-fade-transition"
      >
      
     
        <v-tab hide-slider class="menu-item">{{ item.label }}
    
        </v-tab>
      
      </router-link>
      <v-tab hide-slider
      @click="dialog = true"
      >
      <v-dialog
      overlay dark
      v-model="dialog"
      width="auto"
    >
      <template v-slot:activator="{  on }">
        <v-btn
          color="#f0d8a7"
          v-on="on"
          
        >
          Register
        </v-btn>
        
      </template>



  <v-form @submit.prevent="sendEmail">
      <v-card>
    <v-card-title>
      <span class="menu_item">Sign up and start earning</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"

          >
            <v-text-field
              label="First Name*"
              v-model="name"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          
          >

            <v-text-field
              label="Last Name*"
              
              persistent-hint
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Email*"
              v-model="email"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Password*"
              type="password"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
          </v-col>
        </v-row>
      </v-container>
      <small>*indicates required field</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
      class="menu_item"
        variant="text"
        @click="dialog = false"
      >
        Close
      </v-btn>
      <v-btn
       class="menu_item"
        type="submit"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card> 
  </v-form>
    </v-dialog>
  </v-tab>
    </v-tabs>

 
</v-app-bar>
<!-- Add a navigation bar -->
<v-navigation-drawer
 v-model="drawer"
 absolute
 temporary
>


 <v-list
   nav
   dense
 >
     <router-link v-for="(item, index) in items" :key="index" :to="item.routerLink">
      <v-list-item>
       <v-list-item-title class="main-logo-mob" @click="tab = index">{{ item.label }}</v-list-item-title>
      </v-list-item>
      
     </router-link>
     


 </v-list>
</v-navigation-drawer>

</template>

<script>
 import HeadingBlock from './HeadingBlock.vue';
import RegisterComponent from '../../components/RegisterComponent.vue';




export default {
    data: function () {
        return {
            components: {
                HeadingBlock,
                RegisterComponent
            },
            dialog: false,
            isMobile: false,
            drawer: false,
            tab: null,
            items: [
                { label: "Home", routerLink: "/" },
                { label: "Contact", routerLink: "/contact" }
            ],
        };
    }
}
</script>

<style>



.main-logo-mob {
  text-decoration: none;
  background: -webkit-linear-gradient(#f0d8a7, white);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu_item {
  color: #f0d8a7 !important;
  text-decoration: none;
}

.v-slide-group__content .router-link-active {
 border-bottom:3px solid #f0d8a7;
  

}
.main_logo {
  color: #f0d8a7;
}

.v-btn__content {
  text-decoration: none;
}

.v-overlay__scrim {
  background: rgba(64, 63, 60, 1)
}
</style>