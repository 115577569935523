 <template>
      <div>
        
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition" mode="out-in">
        <component :is="Component" :key="$route.path">
        </component>
        </transition>
      </router-view>
        </div>

</template>
<script>
export default {
}


</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


a {
  text-decoration: none;
}

.menu-item {
  color: #f0d8a7 !important;
}

body {
  background-color: rgb(33, 33, 33);
}


</style>