import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/HomeComponent.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { transition: 'fade', loader: true },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../components/ContactUs.vue'),
    meta: { transition: 'fade', loader:true },
  },
]




const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  
})



export default router



